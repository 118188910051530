export default {
  SignUp: 'Регистрация',
  Login: 'Вход',
  Home: 'Дом',
  Features: 'Функции',
  Pricing: 'Цены',
  Contact: 'Контакт',
  AllInOneTitle:
    'Ваш универсальный криптоинструмент для мониторинга, выписок, поиска транзакций и даже больше',
  AppFeatures: 'Функции приложения',
  MonitorAddresses: 'Контроль адресов',
  MonitorAddressesSubText:
    'Будьте в курсе с уведомлениями в режиме реального времени для Вашего кошелька',
  WalletStatements: 'Выписки по счетам',
  WalletStatementsSubText:
    'Получайте отчеты о переводах по вашему адресу, включая фиатные ценности за любой промежуток времени. Сделайте вашу налоговую отчетность и бухгалтерский учет менее хлопотными',
  CrossBlockchainTransactionSearch: 'Поиск кросс-блокчейновых переводов',
  CrossBlockchainTransactionSearchSubText:
    'Легко находите и исследуйте информацию о переводах, прошедших через несколько блокчейнов, узнавайте детальную информацию о платежах',
  WalletBalanceChecker: 'Просмотр баланса кошельков',
  WalletBalanceCheckerSubText:
    'Получайте полную картину счетов на ваших кошельках в режиме реального времени',
  CreateWallet: 'Создайте кошелек',
  CreateWalletSubText:
    'Создайте ваш собственный счет сохраняйте полный контроль над вашим персональным ключом',
  LetsPickTheBestPlan: 'Давайте выберем лучший план !',
  LetsPickTheBestPlanSubText:
    'Выбор идеального плана - первый шаг к успешному старту. Чтобы выбрать наилучшее для вас, вы можете воспользоваться помощью экспертов.',
  Free: 'Простой старт для всех',
  Basic: 'Простой старт для всех',
  Standard: 'От малого до среднего бизнеса',
  Premimum: 'Решение для больших организаций',
  OnSignup: 'При входе',
  Buy: 'Приобрести',
  NOTE: 'ЗАМЕТКА',
  PlanDescription:
    '"В выписках, 1 RP (Очко запроса) равняется 1 дню.” Например, 7 RP необходимы для генерации отчета за 7 дней.',
  Copyright: 'Авторское право',
  CryptoWatch: 'Crypto Watch',
  AllRightsReserved: 'Все права защищены',
  ContactUs: {
    ContactUsTitle: 'Связаться с нами',
    Title:
      'Если у вас есть какие-либо вопросы, проблемы или предложения, не стесняйтесь обращаться к нам по следующим электронным письмам',
    ForSupportQueries: 'Для запросов в службу поддержки',
    ForSupportQueriesEmail: 'support@coinrex.in',
  },
};
